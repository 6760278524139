// generic

<template>
  	<v-container fluid pa-0>
		<v-card tile>
			<v-toolbar dense flat color="blue-grey darken-3">
				<v-btn ripple icon @click="$router.back()"><v-icon color="black">mdi-arrow-left-bold-box</v-icon></v-btn>
				<v-text-field flat solo dense hide-details background-color="blue-grey darken-3" :prefix="prefix" readonly
					:value="item.name"
				></v-text-field>  
				<v-spacer />
				<v-hover v-slot:default="{ hover }">
				<v-btn small fab color="grey darken-3" :class="{ 'success': hover }" @click.stop="onEdit()" :loading="false" :disabled="false">
				<v-icon>create</v-icon></v-btn></v-hover>
				<v-hover v-slot:default="{ hover }">
				<v-btn small fab color="grey darken-3" :class="{ 'primary': hover }" @click.stop="onPrint()" :loading="false" :disabled="false">
				<v-icon>print</v-icon></v-btn></v-hover>
				<v-btn disabled plain small>ID: {{ item.id }}</v-btn>
				<v-btn ripple icon><v-icon color="yellow darken-3">mdi-star</v-icon></v-btn>
			</v-toolbar>
			<v-tabs v-model="htab" background-color="blue-grey darken-3" color="grey" active-class="blue-grey darken-1 black--text"
				align-with-title
				slider-color="blue-grey darken-1"
				dark dense height="35px"
				next-icon="mdi-arrow-right-bold-box-outline"
				prev-icon="mdi-arrow-left-bold-box-outline"
				show-arrows show-arrows-on-hover center-active
			>
				<v-tab v-for="(item, hindex) in cfgRefTables" :key="hindex">
					{{ item.table_name }}
				</v-tab>
			</v-tabs>
			<v-tabs-items v-model="htab">
				<v-tab-item v-for="(item, hindex) in cfgRefTables" :key="hindex" class="secondary">
					<GenericItem v-if="genericItemModul(hindex)"
						:modulData="modulData(hindex)" />
					<GenericSubList v-if="genericListModul(hindex)"
						:modulData="modulData(hindex)" @updated="onSubListUpdate()" />
				</v-tab-item>
			</v-tabs-items>
		</v-card>
    	<GenericForm v-if="edit" :modulData="modulData()" @cancelled="editCancelled()" @submitted="editSubmitted()" />
    	<v-snackbar v-model="snack.active" :color="snack.type"> {{ snack.text }}
      		<v-btn text dark @click="snack.active = false">Ok</v-btn>
    	</v-snackbar>
        <v-overlay absolute :value="loading">
        	<v-progress-circular indeterminate size="64" width="7" color="cyan darken-4"></v-progress-circular>
        </v-overlay>
	</v-container>
</template>
<script>
import GenericSubList from './GenericSubList'
import GenericItem from './GenericItem'
import GenericForm from './GenericForm'
export default {
	name: 'GenericView',
	components: {
		GenericSubList, GenericItem, GenericForm
	},
	props: {
		routeData: {
			type: Object,
			required: true,
			default: null,
		},
	},
	data: () => ({
		search: '',
		cfgTable: {},
		cfgRefTables: [],
		htab: 0,
		item: {},
		edit: false,
		snack: { active: false, type: 'success', text: 'saved', },
	}),
	computed: {
		prefix() {
			if(!this.cfgTable) return ''
			return this.cfgTable.model_title + ':'
		}
	},
	methods: {
		modulData(tabindex = 0) {
			if(!this.routeData) return null
			const modulData = Object.assign({}, this.routeData)
			modulData.meta.item = this.item
			modulData.meta.model = this.item
			modulData.meta.subtable = this.cfgRefTables[tabindex].table_name
			return modulData
		},
		async getData() {
			this.loading = true
			const param = {
				tablename: this.routeData.meta.tablename,
				data: this.routeData.params,
			}
            try {
                await this.$store.dispatch('generic/getItem', param)
                const result = this.$store.getters['generic/getItem']
                this.item = Object.assign({}, result)
				//console.log('item:', this.item)
            } catch (error) {
                console.error(error)
                this.message.title = error.name
                this.message.msg = '<code>' + JSON.stringify(error.message).substring(0, 200) + '</code>'
                this.showmessage = true
            } finally {
                this.loading = false
            }
		},
		genericItemModul(tabindex) {
			if(!this.item.id) return false
			if(this.cfgRefTables[tabindex].table_name==this.routeData.meta.tablename) return true
		},
		genericListModul(tabindex) {
			//console.log('list?', this.htab, tabindex, this.cfgRefTables[tabindex].table_name, this.routeData.meta.tablename)
			if(!this.item.id) return false
			if(this.htab != tabindex) return false
			if(this.cfgRefTables[tabindex].table_name!=this.routeData.meta.tablename) return true
		},
		onSearch(){
			
		},
		onEdit(){
			this.edit = true
		},
		editCancelled () {
			this.edit = false
			this.snack = {active: true, type: 'warning', text: 'cancelled'}
		},
		editSubmitted() {
			this.getData()
			this.edit = false
			this.snack = {active: true, type: 'success', text: 'saved'}
		},
		onSubListUpdate() {
			console.log('updated')
			this.getData()
		},
		onSearchClear(){
			
		},
		onPrint() {
			const path = '/' + this.cfgTable.model_name + '/' + this.item.id + '/print'
			//window.open(document.location.origin + path, "_blank")
			window.open('http://app.test' + path, "_blank")
		},
		getCfgs() {
			const param = {
				table_name: this.routeData.meta.tablename,
			}
			this.cfgTable = this.$store.getters['core/getCfgTable'](param)
			this.cfgRefTables = this.$store.getters['core/getCfgRefTables'](param)
		},
	},
	beforeMount() {
		//console.log('generic [routeData]:', this.routeData)
		this.getCfgs()
		this.getData()
	},
};
</script>
<style scoped>
div >>> .v-text-field__prefix {
    color: rgba(255, 255, 255, 0.3);
}
</style>